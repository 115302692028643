import { FC, useCallback, useEffect } from "react";
import {
  Button,
  Spinner,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Provider, TenantApi } from "../../../api/TenantApi";
import { useUserProfile } from "../../DashBoard/hooks/useUserProfile";
import { useStoreContext } from "../../../context/StoreContext";
import { UserInfo } from "@microsoft/teamsfx";
import { SITE_CONTACT_US_URL } from "../../../constants/site";
import { Open16Filled } from "@fluentui/react-icons";
import { QueryKey } from "../../../constants/api";
import { useGoogleAnalytics } from "../../../hooks/useGoogleAnalytics";

export type CreateTenantForm = {
  providerTenantId: string;
  providerName: Provider;
  providerUserId: string;
  providerUserName: string;
};

const useStyles = makeStyles({
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    ...shorthands.gap(tokens.spacingHorizontalM),
  },
});

interface SetupTenantProps {}

const SetupTenant: FC<SetupTenantProps> = () => {
  const styles = useStyles();
  const { teamsUser } = useUserProfile();
  const { isTenantConfigured, setIsTenantConfigured } = useStoreContext();
  const { sendPageView } = useGoogleAnalytics();

  useEffect(() => {
    sendPageView({
      page: "/setup-tenant",
      title: "Setup Tenant",
    });
  }, []);

  const queryClient = useQueryClient();
  const { status, mutateAsync: createTenant } = useMutation({
    mutationKey: [QueryKey.CreateTenant],
    mutationFn: TenantApi.createTenant,
  });

  const completeSetup = useCallback(() => {
    // Invalidate the tenant query to refetch the data and redirect to the source page
    // Note: if configuration started from a personal tab, we should redirect to the tab
    setIsTenantConfigured(true);

    queryClient.invalidateQueries({
      queryKey: [QueryKey.GetTenant, teamsUser?.tenantId],
    });
  }, [queryClient, setIsTenantConfigured, teamsUser]);

  const setupTenant = useCallback(
    async (data: CreateTenantForm) => {
      try {
        const response = await createTenant(data);

        if (response?.id) {
          completeSetup();
        }
      } catch (exception: unknown) {
        const error = exception as Error;
        if (
          error &&
          error?.message.indexOf("ERROR_TENANT_ALREADY_EXISTS") > -1
        ) {
          completeSetup();
          return;
        }
      }
    },
    [createTenant, completeSetup]
  );

  const onUserLoaded = useCallback(
    async (user: UserInfo) => {
      await setupTenant({
        providerTenantId: user.tenantId,
        providerName: Provider.MicrosoftTeams,
        providerUserId: user.objectId,
        providerUserName: user.displayName,
      });
    },
    [setupTenant]
  );

  useEffect(() => {
    if (teamsUser && isTenantConfigured === false) {
      onUserLoaded(teamsUser);
    }
  }, [teamsUser, isTenantConfigured, onUserLoaded]);

  return (
    <div data-testid="setup--tenant">
      {status === "pending" && (
        <>
          <p>
            Get ready to spread joy, appreciation, and deliciousness with your
            teammates! Dive into a world of pizza fun, where every slice counts
            and every endorsement brings smiles.
          </p>
          <h2>Let the pizza party begin!</h2>

          <Spinner label="Setting up your tenant..." />
        </>
      )}

      {status === "error" && (
        <>
          <>
            <h2>Bad news...</h2>
            <p>
              Sorry for the inconvenience but it seems like we can't setup your
              organization at the moment.. there might be a problem with our
              oven.
            </p>
            <p>
              Please try again later or if you continue to experience the same
              issue feel free to contact us, and let us know what happened.
            </p>
            <p>Thank you for your understanding!</p>
            <div className={styles.actionButtons}>
              <Button onClick={() => onUserLoaded(teamsUser!)}>
                Try again
              </Button>
              <Button
                as="a"
                appearance="subtle"
                href={SITE_CONTACT_US_URL}
                target="_blank"
                rel="noreferrer"
              >
                <Open16Filled /> &nbsp; Report an issue
              </Button>
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default SetupTenant;
